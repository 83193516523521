import React from 'react'
import { LayoutLogIn } from '../../Layout/LogIn-Layouts-Components/LayoutLogIn/LayoutLogIn'

export const LogInPage = () => {
  return (
    <>
        <LayoutLogIn />
    </>
  )
}
