import React from 'react';
import { LayoutDateUser } from '../../Layout/DataUser-Layout-Components/LayoutDataUser';

export const DataUserPage=()=> {
  return (
    <div>
        <LayoutDateUser/>
    </div>
  )
}
