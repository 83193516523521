import React from 'react';
import { LayoutMyProducts } from '../../Layout/MyProducts-Layout-Components/LayoutMyProducts';

export const MyProductsPage=() =>{
  return (
    <div>
      <LayoutMyProducts/>
    </div>
  )
}
