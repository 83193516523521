import React from 'react'
import { LayoutEditUser } from '../../Layout/EditDataUser-Layout-Components/LayoutEditUser';

export const EditUserPage=()=> {
  return (
    <div>
        <LayoutEditUser/>
    </div>
  )
}
