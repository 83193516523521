import React from "react";
import ImgLogotype from '../../../Images/Logotype-Image/Logotype.png'

export const UILogos = () => {
  return (
    <>
      <div className="containerLogotype">
        <img src={ImgLogotype} className="imgLogotype"></img>
      </div>
    </>
  );
};
